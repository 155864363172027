<template>
    <a-modal
      :title="title"
      :width="640"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @ok="handleSubmit"
      @cancel="handleCancel"
      destroyOnClose
    >
      <a-spin :spinning="confirmLoading">
        <a-form class="addClass" autocomplete="off">
          <a-form-item label="大屏名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input v-model="name" placeholder="请输入大屏名称" />
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </template>
  
  <script>
  import { bigScreenUIUpdateName } from '@/api/other'
  export default {
    data() {
      return {
        title: '',
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        },
        visible: false,
        confirmLoading: false,
        btnShow: true,
        name: '',
        id: ''
      }
    },
    methods: {
      showCreateTmForm(id) {
        this.visible = true
        this.title = '新增学科'
        this.btnShow = true
        this.name = ''
        this.id = id
      },
      addCreateTmForm(record) {
        console.log('编辑大屏名称', record)
        this.visible = true
        this.title = '编辑大屏名称'
        this.btnShow = false
        this.name = record.name
        this.id = record.bigScreenId
      },
      handleSubmit() {
        // 新增确认操作
        this.confirmLoading = true
        if (this.btnShow) {
          this.insert()
        } else {
          this.eidt()
        }
      },
    /*   insert() {
        if (!this.disciplineName) {
          this.$message.error('未填写完整')
          this.confirmLoading = false
          return
        }
        xuekeV2Add(
          {
            disciplineName: this.disciplineName,
            xueduanId: this.id
          },
          (resp) => {
            console.log(resp)
            if (resp.code === 0) {
              this.confirmLoading = false
              this.$message.success(`增加成功`)
              this.visible = false
              this.$emit('ok', '')
            } else {
              this.$message.error(resp.errMsg)
              this.confirmLoading = false
            }
          },
          (err) => {
            console.log('err', err)
            this.confirmLoading = false
          }
        )
      }, */
      eidt() {
        if (!this.name) {
          this.$message.error('未填写完整')
          this.confirmLoading = false
          return
        }
  
        bigScreenUIUpdateName(
          {
            name: this.name,
            bigScreenId: this.id
          },
          (resp) => {
            console.log(resp)
            if (resp.code === 0) {
              this.confirmLoading = false
              this.$message.success(`修改成功`)
              this.visible = false
              this.$emit('ok', '')
            } else {
              this.$message.error(resp.errMsg)
              this.confirmLoading = false
            }
          },
          (err) => {
            this.confirmLoading = false
            console.log('增加错误', err)
          }
        )
      },
      handleCancel() {
        // 取消操作
        this.visible = false
      }
    },
    mounted() {}
  }
  </script>
  
  <style scoped></style>
  