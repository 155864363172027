<template>
  <div class="MyProject">
    <div class="FirstPage_right_bottom_title">
      <span class="FirstPage_right_bottom_title_span">{{navId==1?'我的项目':'模板中心'}}</span>
      <a-radio-group v-model="useType" button-style="solid" @change="radioChange()">
        <a-radio-button :value="0">大屏</a-radio-button>
        <a-radio-button :value="1">电子班牌 </a-radio-button>
      </a-radio-group>
    </div>
    <div class="FirstPage_right_bottom_content">
      <div class="FirstPage_right_bottom_content_box" @click="navId==1?goSetPage(item) :createEmpty(item.bigScreenId)"
        v-for="(item, index) in dataList" :key="index">
        <img v-if="useType==0"
          :src="item.img ? item.img : 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1693470908376.jpg'"
          class="FirstPage_right_bottom_content_box_img" />
        <img v-if="useType==1"
          :src="item.img ? item.img : 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1705392530381.jpg'"
          class="FirstPage_right_bottom_content_box_img" />
        <div class="FirstPage_right_bottom_content_box_bottom">
          <span class="FirstPage_right_bottom_content_box_bottom_left" v-if="useType==0">
            <a-tooltip>
              <template slot="title">
                {{ item.name }}
              </template>
              {{ item.name }}
            </a-tooltip>
          </span>
          <span class="FirstPage_right_bottom_content_box_bottom_left" v-else>
            <a-tooltip>
              <template slot="title">
                {{item.bclassName}} - {{ item.name }}
              </template>
              {{item.bclassName}} - {{ item.name }}
            </a-tooltip>
          </span>
          <div class="FirstPage_right_bottom_content_box_bottom_right" v-if="navId==1">
            <span class="FirstPage_right_bottom_content_box_bottom_right_span" v-if="!item.domainUrl">未发布</span>
            <a-popover title="" placement="bottom">
              <template slot="content">
                <p>大屏地址：{{item.domainUrl}}</p>
              </template>
              <span class="FirstPage_right_bottom_content_box_bottom_right_span" v-if="item.domainUrl">已发布</span>
            </a-popover>
            <span class="FirstPage_right_bottom_content_box_bottom_right_span2">
              <a-popover title="" placement="bottom"
                overlayClassName="FirstPage_right_bottom_content_box_bottom_right_popover">
                <template slot="content">
                  <div class="popover_content">
                    <span v-if="subsmallSchoolId !== '0' && item.domainId && useType==0" class="popover_content_span" @click="
                        $refs.setHttp.editModel(subsmallSchoolId, item, useType)
                      ">编辑域名</span>
                      <span v-if="subsmallSchoolId !== '0' && item.domainId && useType==1" class="popover_content_span" @click="$refs.selectBclass.eidtModel(subsmallSchoolId, item)">切换班级</span>
                    <span class="popover_content_span" @click="$refs.addBigScreet.addCreateTmForm(item)">重命名</span>
                    <template>
                      <a-popconfirm title="是否删除?" @confirm="delMem(item)">
                        <span class="popover_content_span">删除</span>
                      </a-popconfirm>
                    </template>
                  </div>
                </template>
                <a-icon type="more" />
              </a-popover>
            </span>
          </div>
        </div>
      </div>
      <div class="FirstPage_right_bottom_content_box2" @click="createEmpty()" v-if="navId==1 && useType==0">
        <div class="FirstPage_right_bottom_content_box2_div">
          <img src="../../assets/img/imgStaly1/add_icon.png" class="FirstPage_right_bottom_content_box2_div_img"
            alt="" />
          <span class="FirstPage_right_bottom_content_box2_div_span">新增项目</span>
        </div>
      </div>
      <div class="FirstPage_right_bottom_content_box2" @click="$refs.selectBclass.showModel(subsmallSchoolId)" v-if="navId==1 && useType==1">
        <div class="FirstPage_right_bottom_content_box2_div">
          <img src="../../assets/img/imgStaly1/add_icon.png" class="FirstPage_right_bottom_content_box2_div_img"
            alt="" />
          <span class="FirstPage_right_bottom_content_box2_div_span">新增项目</span>
        </div>
      </div>
    </div>
    <div class="TeachingPlan_bottom" v-if="useType==1 && navId==1">
      <a-pagination :current="pageNo" :total="totalNum" :page-size="pageSize" @change="changePage" />
    </div>
    <set-http ref="setHttp" @close="loadData(subsmallSchoolId)" />
    <AddBigScreet ref="addBigScreet" @ok="loadData(subsmallSchoolId)" />
    <SelectBclass ref="selectBclass" @ok="changeClass"/>
  </div>
</template>

<script>
  import {
    bigScreenUIList,
    bigScreenUIDel,
    bigScreenCreate,
    electronicCardListPage
  } from "@/api/other";
  import Vue from "vue";
  import SetHttp from "@/XyyPopups/SetHttp";
  import AddBigScreet from "@/XyyPopups/AddBigScreet";
  import SelectBclass from "@/XyyPopups/SelectBclass";
  import { ACCESS_TOKEN } from "@/store/mutation-types";
  
  export default {
    name: "MyProject",
    components: { SetHttp, AddBigScreet, SelectBclass },
    props: ["navId", 'currentSubSchoolId'],
    data() {
      return {
        dataList: [],
        useType: 0,
        pageSize: 12,
      pageNo: 1,
      totalNum: 0,
      bclassId: '',
      subsmallSchoolId: ''
      };
    },
    methods: {
      loadData(currentSubSchoolId) {
        //   this.tableLoading = true
        this.dataList = [];
        this.subsmallSchoolId = currentSubSchoolId
        bigScreenUIList(
          {
            subSchoolId: currentSubSchoolId,
            useType: this.useType
          },
          (resp) => {
            console.log(resp);
            if (resp.code === 0) {
              this.dataList = resp.data;
            } else {
              this.$message.error(resp.errMsg);
            }
          },
          (err, code) => {
            console.log("err", err);
            // this.confirmLoading = false
          }
        );
      },
      changePage(page, pageSize) {
      this.pageNo = page
      this.getElectronic(this.subsmallSchoolId)
    },
    changeClass(bclassId, type){
      this.bclassId = bclassId * 1
      localStorage.setItem('bclassId', this.bclassId)
      // type=1为创建空班牌 type=2为切换班级
      if(type*1 === 1){
        this.createEmpty()
      }else{
        this.loadData(this.subsmallSchoolId)
      }
    },
      getElectronic(currentSubSchoolId) {
        this.subsmallSchoolId = currentSubSchoolId
        electronicCardListPage(
          {
            subSchoolId: currentSubSchoolId,
            page: this.pageNo,
            pageSize: this.pageSize,
            useType: 1
          },
          (resp) => {
            console.log(resp);
            if (resp.code === 0) {
              this.dataList = resp.data.result;
              this.totalNum = resp.data.page.count
            } else {
              this.$message.error(resp.errMsg);
            }
          },
          (err, code) => {
            console.log("err", err);
            // this.confirmLoading = false
          }
        )
      },
      radioChange() {
        if (this.useType === 0) {
          this.loadData(this.subsmallSchoolId)
        } else {
          this.pageNo = 1
          this.getElectronic(this.subsmallSchoolId)
        }
      },
      createEmpty(bigScreenId) {
        if(this.navId===2){
          return
        }
        bigScreenCreate(
          {
            subSchoolId: this.subsmallSchoolId,
            useType: this.useType,
            bclassId: this.bclassId
          },
          (resp) => {
            console.log("resp====>", resp);
            this.goSetPage(resp.data, bigScreenId);
          },
          (err) => {
            console.log("err", err);
          }
        );
      },
      delMem(item) {
        console.info("item", item);
        bigScreenUIDel(
          { bigScreenId: item.bigScreenId },
          (resp) => {
            if (resp.code === 0) {
              this.$message.success(`删除成功`);
              if (this.useType === 0) {
                this.loadData(this.subsmallSchoolId)
              } else {
                this.getElectronic(this.subsmallSchoolId)
              }
            } else {
              this.$message.error(resp.errMsg);
            }
          },
          (err) => {
            console.log("删除失败错误", err);
          }
        );
      },
      goSetPage(item, bigScreenId) {
        const that = this;
        if(this.useType===1){
          localStorage.setItem('bclassId', item.bclassId)
        }
        this.$router.push({
          name: "SetBigScreet",
          query: {
            record: JSON.stringify(item),
            currentSubSchoolId: that.subsmallSchoolId,
            bigScreenIds: bigScreenId,
            useType: this.useType
          },
        });
      },
    },
    mounted() {
    },
    beforeMount() {
      // const that = this;
    },
  };
</script>
<style scoped>
  .MyProject {
    width: 100%;
  }

  .FirstPage_right_bottom_title {
    padding: 10px 0 20px 0;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .FirstPage_right_bottom_content {
    display: flex;
    flex-wrap: wrap;
  }

  .FirstPage_right_bottom_content_box {
    border: 1px solid #535353;
    padding: 10px;
    width: 19%;
    margin-right: 1.2%;
    margin-bottom: 2%;
    border-radius: 8px;
    cursor: pointer;
  }

  .FirstPage_right_bottom_content_box:nth-child(5n) {
    margin-right: 0;
  }

  .FirstPage_right_bottom_content_box_img {
    width: 100%;
    height: 100%;
    min-height: 120px;
  }

  .FirstPage_right_bottom_content_box_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  .FirstPage_right_bottom_content_box_bottom_left {
    font-size: 15px;
    color: #fff;
    width: calc(100% - 65px);
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
  }

  .FirstPage_right_bottom_content_box_bottom_right {
    display: flex;
    align-items: center;
    width: 65px;
  }

  .FirstPage_right_bottom_content_box_bottom_right_span {
    color: #ffffffa6;
  }

  .FirstPage_right_bottom_content_box_bottom_right_span2 {
    color: #ffffffa6;
    font-size: 18px;
    display: inline-block;
    margin-left: 5px;
  }

  .popover_content {
    display: flex;
    flex-direction: column;
  }

  .popover_content_span {
    display: inline-block;
    padding: 0 0 10px;
    color: #fff;
    cursor: pointer;
  }

  .popover_content_span:last-child {
    padding-bottom: 0;
  }

  .FirstPage_right_bottom_content_box2 {
    display: flex;
    border: 1px solid #535353;
    padding: 20px;
    width: 19%;
    background-color: #000;
    margin-bottom: 2%;
    border-radius: 8px;
  }

  .FirstPage_right_bottom_content_box2_div {
    background-color: #333333;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
  }

  .FirstPage_right_bottom_content_box2_div_img {
    margin-bottom: 10px;
  }

  .FirstPage_right_bottom_content_box2_div_span {
    font-size: 15px;
    color: #fff;
  }
  .TeachingPlan_bottom {
  margin-top: 20px;
  text-align: right;
}
</style>