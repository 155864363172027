<template>
  <div class="FirstPage">
    <div class="FirstPage_left">
      <div class="FirstPage_left_top">
        <div class="FirstPage_left_top_box">
          <img
            class="FirstPage_left_top_img"
            src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
            alt=""
          />
          <span class="FirstPage_left_top_span">{{ schoolName }}</span>
        </div>
        <a-select
          v-model="currentSubSchoolId"
          @change="schoolChange"
          class="FirstPage_left_top_select"
        >
          <a-select-option :value="item.id" v-for="(item, index) in schoolList" :key="index"> {{item.name}} </a-select-option>
        </a-select>
      </div>
      <div class="FirstPage_left_bottom">
        <div class="FirstPage_left_bottom_nav" @click="navClick(item)" :class="item.id==navId?'FirstPage_left_bottom_nav_actived':''" v-for="item in navList" :key="item.id">
          <a-icon :type="item.icon" class="FirstPage_left_bottom_nav_icon" />
          <span class="FirstPage_left_bottom_nav_span">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="FirstPage_right">
      <div class="FirstPage_right_top">
        <img
          src="../assets/img/imgStaly1/screen_setting_bg.jpg"
          class="FirstPage_right_top_img"
          alt=""
        />
        <span class="FirstPage_right_top_span">学校大数据可视化管理系统</span>
      </div>
      <div class="FirstPage_right_bottom">
       <MyProject ref="myProject" :currentSubSchoolId="currentSubSchoolId" v-show="navId==1||navId==2" :navId="navId"/>
       <JointScreenCenter ref="jointScreenCenter" :currentSubSchoolId="currentSubSchoolId" v-show="navId==3"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  bigScreenUIList,
  bigScreenUIDel,
  bigScreenCreate,
  getToken,
  authInfo
} from "@/api/other";
import Vue from "vue";
// import SetHttp from "@/XyyPopups/SetHttp";
// import AddBigScreet from "@/XyyPopups/AddBigScreet";
import MyProject from '@/components/CustomModule/MyProject'
import JointScreenCenter from '@/components/CustomModule/JointScreenCenter'
import { ACCESS_TOKEN } from "@/store/mutation-types";
export default {
  name: "FirstPage",
  components: { MyProject, JointScreenCenter },
  data() {
    return {
      schoolName: "",
      imgSrc: "",
      visible: false,
      currentSubSchoolId: '',
      dataList: [],
      schoolList: [],
      navId : 1,
      navList: [
        {
          name: '我的项目',
          icon: 'profile',
          id: 1 
        },
        {
          name: '联屏中心',
          icon: 'profile',
          id: 3
        },
        {
          name: '模板中心',
          icon: 'profile',
          id: 2
        }
      ]
    };
  },
  methods: {
    navClick (item) {
      this.navId = item.id
      if (this.navId == 1) {
        this.loadData(this.currentSubSchoolId)
      }
      if (this.navId == 2) {
        this.loadData('0')
      }
      if(this.navId == 3){
        this.$refs.jointScreenCenter.loadData()
      }
    },
    loadData(currentSubSchoolId){
      this.$refs.myProject.loadData(currentSubSchoolId)
    },
   getInfo(){
      const userId = parseInt(Math.random()*1000);
      const paw = parseInt(Math.random()*100000)
      authInfo(
        { 
          asefgv: userId,
          wdvgh: paw
        },
        (resp) => {
          if (resp.code === 0) {
            const respData =  resp.data
            this.imgSrc = respData.avatar
            this.schoolName = respData.name
          } else {
            this.$message.error(resp.errMsg);
          }
        },
        (err) => {
          console.log("删除失败错误", err);
        }
      );
    },
    getUser() {
      const userId = parseInt(Math.random()*1000);
      const paw = parseInt(Math.random()*100000)
      const storageSubSchoolId = Vue.ls.get("subSchoolId")
      getToken(
        { 
          ids: userId,
          paw: paw
        },
        (resp) => {
          if (resp.code === 0) {
            const respData =  resp.data
            if (respData.subSchools.length == 0) {
              respData.subSchools.push({
                name: '超管',
                id: '0'
              })
            }
            this.schoolList = respData.subSchools
            for (let i = 0; i < respData.subSchools.length; i++) {
              const e = respData.subSchools[i];
              if(storageSubSchoolId === e.id){
                this.currentSubSchoolId = e.id
              }
            }
            if(!this.currentSubSchoolId){
              this.currentSubSchoolId = this.schoolList[0].id
              if(!storageSubSchoolId){
                Vue.ls.set("subSchoolId", this.schoolList[0].id)
              }
            }
            this.getInfo()
            this.loadData(this.currentSubSchoolId);
          } else {
            this.$message.error(resp.errMsg);
          }
        },
        (err) => {
          console.log("删除失败错误", err);
        }
      );
    },
    schoolChange() {
      this.loadData(this.currentSubSchoolId)
      Vue.ls.set("subSchoolId", this.currentSubSchoolId)
    },
    getUrlParam(key) {
    var url = window.location.href;
    var startIndex = url.indexOf('?');
    var params = {};
    if (startIndex > -1) {
       var str = url.substring(startIndex + 1);
       var arr = str.split('&');
       for (var i = 0; i < arr.length; i++) {
           var kv = arr[i].split('=');
           if (kv[1].indexOf('#') > -1) {
              kv[1] = kv[1].substring(0, kv[1].indexOf('#'));
           }
           params[kv[0]] = kv[1];
        }
      }
     return params[key];
  },
    getParam() {
      const that = this
      let curToken = Vue.ls.get(ACCESS_TOKEN);
      // const token = this.$route.query.token;
      const token = this.getUrlParam('token')
      if (!curToken || curToken !== token) {
        if (token) {
          Vue.ls.set(ACCESS_TOKEN, token);
        }
      }
      curToken = Vue.ls.get(ACCESS_TOKEN)
      if(!curToken){
        this.$router.go(0)
        return
      }
        that.getUser()
    },
  },
  mounted() {
    this.getParam()
    // this.$router.push({
    //     name: "Banner"
    //   });
  },
  beforeMount() {
    // const that = this;
  },
};
</script>
<style scoped>
html,
body {
  height: 100%;
  width: 100%;
}
#app {
  height: 100%;
  width: 100%;
}
.FirstPage {
  display: flex;
  height: 100%;
}
.FirstPage_left {
  width: 300px;
  height: 100%;
}
.FirstPage_left_top {
  padding: 20px;
  background-color: #2e2e2e;
}
.FirstPage_left_top_box {
  display: flex;
  align-items: center;
}
.FirstPage_left_top_select {
  width: 100%;
  margin-top: 10px;
}
.FirstPage_left_top_img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
.FirstPage_left_top_span {
  color: #fff;
  font-size: 16px;
}
.FirstPage_left_bottom {
  background-color: #333333;
  padding: 0 5px 20px;
  height: calc(100% - 117px);
}
.FirstPage_left_bottom_nav {
  padding: 10px 15px;
  color: #ffffffa6;
  cursor:pointer;
}
.FirstPage_left_bottom_nav_actived {
  background-color: #3370ff;
  color: #fff;
}
.FirstPage_left_bottom_nav_span {
  display: inline-block;
  padding-left: 10px;
}
.FirstPage_left_bottom_nav_icon {
  font-size: 18px;
}
.FirstPage_right {
  width: calc(100% - 300px);
  height: 100%;
  background-color: #282828;
}
.FirstPage_right_top {
  position: relative;
}
.FirstPage_right_top_img {
  width: 100%;
  min-height: 113px;
}
.FirstPage_right_top_span {
  font-size: 24px;
  color: #fff;
  position: absolute;
  display: inline-block;
  width: 100%;
  text-align: center;
  left: 0;
  top: 34%;
}
.FirstPage_right_bottom {
  height: calc(100% - 113px);
  background-color: #282828;
  padding: 20px;
  overflow-y: auto;
}
</style>
<style>
.FirstPage_right_bottom_content_box_bottom_right_popover
  .ant-popover-inner-content {
  background-color: #404040 !important;
}
.FirstPage_right_bottom_content_box_bottom_right_popover.ant-popover-placement-bottom
  > .ant-popover-content
  > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-top-color: #404040;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #404040;
  box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
}
.FirstPage_left_top_select .ant-select-arrow {
  color: #fff !important;
}
.FirstPage_left_top_select .ant-select-selection {
  background-color: inherit;
  color: #fff;
}
</style>
