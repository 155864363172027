<template>
  <a-modal :title="title" :width="550" :visible="visible" @ok="handleSubmit" @cancel="handleCancel"
    :confirmLoading="confirmLoading">
    <div style="display: flex;align-items: center;">
      <span>班级:</span>
      <a-select placeholder="请选择班级" v-model="bclassId" style="width: 180px;margin-left: 10px;">
        <a-select-option :value="item.id" v-for="(item, index) in bclassList" :key="index">{{
                    item.name
                    }}</a-select-option>
      </a-select>
    </div>
  </a-modal>
</template>

<script>
  import {
    bigScreenUrlChange,
    bclassSimple
  } from '@/api/other'
  export default {
    data() {
      return {
        title: '',
        http: undefined,
        id: '0',
        visible: false,
        confirmLoading: false,
        iswarning: true,
        currentSubSchoolId: '',
        bclassList: [],
        bclassId: undefined,
        btnShow: true,
        cType: 1
      }
    },
    methods: {
      getBclass() {
        bclassSimple({
            subSchoolId: this.currentSubSchoolId,
          },
          (resp) => {
            console.log('查询班级', resp)
            this.bclassList = resp.data
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err);
          }
        )
      },
      httpChange() {
        this.http = this.http.replace(/[^a-z]/g, '')
      },
      showModel(subSchoolId) {
        if(subSchoolId==0){
          localStorage.setItem('bclassId', 7)
return
        }
        this.visible = true
        this.currentSubSchoolId = subSchoolId
        this.bclassId = undefined
        this.getBclass()
        this.btnShow = true
        this.title = '电子班牌选择班级'
      },
      eidtModel(subSchoolId, item){
        this.visible = true
        this.currentSubSchoolId = subSchoolId
        this.bclassId = item.bclassId
        this.id = item.domainId
        this.getBclass()
        this.btnShow = false
        this.title = '切换班级'
      },
      handleSubmit() {
        if(this.btnShow){
          this.addFun(1)
        }else{
          this.setBigScreenUrlChange()
        }
      },
      addFun(type){
        const that = this
        localStorage.setItem('bclassId', this.bclassId)
        this.visible = false
        this.$emit('ok', this.bclassId, type)
      },
      setBigScreenUrlChange() {
        this.confirmLoading = true
        const urls =  'http://classboard.xueyoyo.com/index.html?id=' + this.bclassId
        bigScreenUrlChange({
            subSchoolId: this.currentSubSchoolId,
            domainId: this.id,
            domainName: urls,
            useType: this.cType
          },
          (resp) => {
            this.confirmLoading = false
            this.$message.success('操作成功')
            this.addFun(2)
          },
          (err) => {
            console.log(err)
            this.confirmLoading = false
          }
        )
      },
      handleCancel() {
        this.visible = false
      }
    },
    mounted() {}
  }

</script>

<style scoped>

</style>
