<template>
  <div class="JointScreenCenter">
    <div class="JointScreenCenter_top">
      <a-radio-group v-model="useType" button-style="solid" @change="radioChange()">
        <a-radio-button :value="0">大屏</a-radio-button>
        <a-radio-button :value="1">电子班牌 </a-radio-button>
      </a-radio-group>
      <a-select placeholder="请选择班级" v-if="useType==1" v-model="bclassId" @change="bclassChange" style="width: 180px;margin-left: 10px;">
        <a-select-option :value="item.id" v-for="(item, index) in bclassList" :key="index">{{
          item.name
          }}</a-select-option>
      </a-select>
    </div>
    <div class="" v-for="(items, indexs) in dataInfoList" :key="indexs">
      <div class="FirstPage_right_bottom_title">
        <a-popover title="" placement="bottom">
          <template slot="content">
            <p>大屏地址：{{items.scrollUrl}}</p>
          </template>
          <span class="FirstPage_right_bottom_title_span">{{
            items.scrollName
            }}</span>
        </a-popover>
        <template>
          <a-popconfirm title="是否删除?" @confirm="delMem(items)">
            <span class="delSpan">
              <a-icon type="delete" />
            </span>
          </a-popconfirm>
        </template>
      </div>
      <div class="FirstPage_right_bottom_content">
        <div class="FirstPage_right_bottom_content_box" v-for="(item, index) in items.list" :key="index">
          <img :src="
              item.img
                ? item.img
                : 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1693470908376.jpg'
            " class="FirstPage_right_bottom_content_box_img" />
          <div class="FirstPage_right_bottom_content_box_bottom">
            <span class="FirstPage_right_bottom_content_box_bottom_left">{{
              item.name
              }}</span>
            <div class="FirstPage_right_bottom_content_box_bottom_right">
              <span class="FirstPage_right_bottom_content_box_bottom_right_span2">
              </span>
            </div>
          </div>
        </div>
        <div class="FirstPage_right_bottom_content_box2" @click="$refs.addJointScreen.addCreateTmForm(bclassId,items,useType)">
          <div class="FirstPage_right_bottom_content_box2_div">
            <img src="../../assets/img/imgStaly1/edit_icon.png" class="FirstPage_right_bottom_content_box2_div_img"
              alt="" />
            <span class="FirstPage_right_bottom_content_box2_div_span">编辑联屏</span>
          </div>
        </div>
      </div>
    </div>
    <div class="FirstPage_right_bottom_content_box2" style="height:211px;"
      @click="$refs.addJointScreen.showCreateTmForm(bclassId,useType)">
      <div class="FirstPage_right_bottom_content_box2_div">
        <img src="../../assets/img/imgStaly1/add_icon.png" class="FirstPage_right_bottom_content_box2_div_img" alt="" />
        <span class="FirstPage_right_bottom_content_box2_div_span">新增联屏</span>
      </div>
    </div>
    <AddJointScreen ref="addJointScreen" @ok="loadData" />
  </div>
</template>

<script>
  import { scrollShowQuery, scrollShowDel, bclassSimple  } from "@/api/other";
  import AddJointScreen from '@/components/CustomModule/AddJointScreen'
  export default {
    name: "JointScreenCenter",
    components: { AddJointScreen },
    props: ["currentSubSchoolId"],
    data() {
      return {
        dataInfoList: [],
        useType: 0,
        bclassId: undefined,
        bclassList: []
      };
    },
    methods: {
      bclassChange(){
        this.loadData()
      },
      radioChange() {
        this.dataInfoList = []
        if (this.useType === 0) {
          this.bclassId = undefined
          this.loadData()
        } else {
          this.getBclass()
        }
      },
      getBclass() {
        bclassSimple({
          subSchoolId: this.currentSubSchoolId,
        },
          (resp) => {
            console.log('查询班级', resp)
            this.bclassList = resp.data
            if(resp.data.length !==0 ){
              this.bclassId = resp.data[0].id
              this.loadData()
            }
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err);
          }
        )
      },
      loadData() {
        //   this.tableLoading = true
        this.dataInfoList = [];
        scrollShowQuery(
          {
            bclassId: this.bclassId
          },
          (resp) => {
            console.log(resp);
            if (resp.code === 0) {
              this.dataInfoList = resp.data;
            } else {
              this.$message.error(resp.errMsg);
            }
          },
          (err, code) => {
            console.log("err", err);
            // this.confirmLoading = false
          }
        );
      },
      delMem(item) {
        console.info("item", item);
        scrollShowDel(
          { scrollId: item.scrollId },
          (resp) => {
            if (resp.code === 0) {
              this.$message.success(`删除成功`);
              this.loadData();
            } else {
              this.$message.error(resp.errMsg);
            }
          },
          (err) => {
            console.log("删除失败错误", err);
          }
        );
      },
    },
    mounted() {
      // this.loadData();
    },
    beforeMount() {
      // const that = this;
    },
  };
</script>
<style scoped>
  .JointScreenCenter {
    width: 100%;
  }

  .delSpan {
    color: red;
    margin-left: 10px;
    font-weight: 700;
    cursor: pointer;
  }

  .FirstPage_right_bottom_title {
    padding: 10px 0 20px 0;
    color: #fff;
    font-size: 16px;
  }

  .FirstPage_right_bottom_content {
    display: flex;
    flex-wrap: wrap;
  }

  .FirstPage_right_bottom_content_box {
    border: 1px solid #535353;
    padding: 10px;
    width: 19%;
    margin-right: 1.2%;
    margin-bottom: 2%;
    border-radius: 8px;
    cursor: pointer;
  }

  .FirstPage_right_bottom_content_box:nth-child(5n) {
    margin-right: 0;
  }

  .FirstPage_right_bottom_content_box_img {
    width: 100%;
    min-height: 120px;
  }

  .FirstPage_right_bottom_content_box_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  .FirstPage_right_bottom_content_box_bottom_left {
    font-size: 15px;
    color: #fff;
  }

  .FirstPage_right_bottom_content_box_bottom_right {
    display: flex;
    align-items: center;
  }

  .FirstPage_right_bottom_content_box_bottom_right_span {
    color: #ffffffa6;
  }

  .FirstPage_right_bottom_content_box_bottom_right_span2 {
    color: #ffffffa6;
    font-size: 18px;
    display: inline-block;
    margin-left: 5px;
  }

  .popover_content {
    display: flex;
    flex-direction: column;
  }

  .popover_content_span {
    display: inline-block;
    padding: 0 0 10px;
    color: #fff;
    cursor: pointer;
  }

  .popover_content_span:last-child {
    padding-bottom: 0;
  }

  .FirstPage_right_bottom_content_box2 {
    display: flex;
    border: 1px solid #535353;
    padding: 20px;
    width: 19%;
    background-color: #000;
    margin-bottom: 2%;
    border-radius: 8px;
  }

  .FirstPage_right_bottom_content_box2_div {
    background-color: #333333;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
  }

  .FirstPage_right_bottom_content_box2_div_img {
    margin-bottom: 10px;
  }

  .FirstPage_right_bottom_content_box2_div_span {
    font-size: 15px;
    color: #fff;
  }

  .JointScreenCenter_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
</style>