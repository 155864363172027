<template>
  <a-modal
    :title="title"
    :width="840"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
    destroyOnClose
  >
    <a-spin :spinning="confirmLoading">
      <a-table
        :pagination="false"
        :loading="tableLoading"
        :rowKey="(record) => record.bigScreenId"
        :columns="columns"
        :dataSource="dataInfo"
      >
        <span slot="pic" slot-scope="text, record">
          <img :src="record.img" alt="" style="max-width: 50px; height: 50px;" />
        </span>
        <span slot="time" slot-scope="text, record">
          <a-input-number
            v-model="record.time"
            placeholder="请输入时间"
            style="width:120px;"
            :min="20"
            :max="1800"
          /> s
        </span>
        <span slot="action" slot-scope="text, record, index">
          <a type="link" :disabled="index === 0" @click="removeFun(1, index)">上移</a>
          <a-divider type="vertical" />
          <a type="link" :disabled="index === dataInfo.length - 1"  @click="removeFun(2, index)">下移</a>
          <a-divider type="vertical" />
          <a @click="delList(record)">删除</a>
        </span>
      </a-table>
      <div class="AddJointScreen_bottom">
        <div
          class="AddJointScreen_bottom_box"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <span class="AddJointScreen_bottom_box_title">{{
            item.schoolName
          }}</span>
          <div class="AddJointScreen_bottom_box_bottom">
            <div
              class="AddJointScreen_bottom_box_bottom_imgBox"
              v-for="(items, indexs) in item.list"
              :key="indexs"
              @click="addList(items)"
            >
              <img
                :src="items.img"
                class="AddJointScreen_bottom_box_bottom_imgBox_img"
                alt=""
              />
              <span class="AddJointScreen_bottom_box_bottom_imgBox_span">
                {{ items.name }}
              </span>
            </div>
            <a-empty v-if="item.list.length == 0" />
          </div>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { uiListBySchool, scrollShowSet } from "@/api/other";
export default {
  data() {
    return {
      title: "新增联屏",
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      btnShow: true,
      tableLoading: false,
      columns: [
        {
          title: "大屏名称",
          dataIndex: "name",
        },
        {
          title: "大屏缩略图",
          scopedSlots: { customRender: "pic" },
        },
        {
          title: "播放时间",
          scopedSlots: { customRender: "time" },
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" }, // 对应的slot名称
        },
      ],
      dataInfo: [],
      dataList: [],
      scrollId:'',
      currentSubSchoolId: '',
      bclassId: ''
    };
  },
  methods: {
    getUIList() {
      uiListBySchool(
        {
          bclassId: this.bclassId
        },
        (resp) => {
          console.log(resp);
          if (resp.code === 0) {
            if (!this.btnShow) {
              console.log(this.dataInfo)
              for (let i = 0; i < resp.data.length; i++) {
                const e = resp.data[i];
                for (let j = 0; j < e.list.length; j++) {
                  const el = e.list[j];
                  for (let k = 0; k < this.dataInfo.length; k++) {
                    const ele = this.dataInfo[k];
                    if (el.bigScreenId === ele.bigScreenId) {
                    this.remove(e.list, el);
                    j = j - 1;
                  }
                  }
                  /*  */
                }
              }
            } else {
              this.dataInfo = [];
              for (let i = 0; i < resp.data.length; i++) {
                const e = resp.data[i];
                for (let j = 0; j < e.list.length; j++) {
                  const el = e.list[j];
                }
              }
            }
            this.dataList = resp.data;
          } else {
            this.$message.error(resp.errMsg);
          }
        },
        (err, code) => {
          console.log("err", err);
          // this.confirmLoading = false
        }
      );
    },
    removeFun(type, index){
        const dataList = this.dataInfo
        this.dataInfo = []
        if(type===1){
            this.dataInfo = this.upIndex(dataList, index)
        }else{
            this.dataInfo = this.downIndex(dataList, index)
        }
    },
    //上移
    upIndex(arr, index) {
        console.log(arr, index)
      var temp;
      if (index < 1 || index > arr.length - 1) {
        return arr;
      }
      temp = arr[index - 1];
      arr = arr.copyWithin(index - 1, index, index + 1); //target, start, end
      arr[index] = temp;
      console.log('上移',arr)
      return arr;
    },
    //下移
    downIndex(arr, index) {
      var temp;
      if (index < 0 || index > arr.length - 1) {
        return arr;
      }
      temp = arr[index + 1];
      arr = arr.copyWithin(index + 1, index, index + 1);
      arr[index] = temp;
      console.log('下移',arr)
      return arr;
    },
    remove(array, val) {
      for (var i = 0; i < array.length; i++) {
        if (array[i] === val) {
          array.splice(i, 1);
        }
      }
      return -1;
    },
    addList(item) {
      console.log(item);
      for (let i = 0; i < this.dataList.length; i++) {
        const e = this.dataList[i];
        if (e.subSchoolId === item.subSchoolId) {
          for (let j = 0; j < e.list.length; j++) {
            const el = e.list[j];
            if (item.bigScreenId === el.bigScreenId) {
              this.dataInfo.push(el);
              this.remove(e.list, el);
              j = j - 1;
            }
          }
        }
      }
    },
    delList(item) {
      for (let i = 0; i < this.dataInfo.length; i++) {
        const e = this.dataInfo[i];
        if (e.bigScreenId === item.bigScreenId) {
          for (let j = 0; j < this.dataList.length; j++) {
            const el = this.dataList[j];
            if (el.subSchoolId === item.subSchoolId) {
              el.list.push(item);
            }
          }
          this.remove(this.dataInfo, e);
        }
      }
    },
    showCreateTmForm(bclassId, useType) {
      this.visible = true;
      this.title = "新增联屏";
      this.btnShow = true;
      this.dataInfo = []
      this.scrollId = 0
      this.bclassId = bclassId
      this.getUIList()
    },
    addCreateTmForm(bclassId, record, useType) {
      console.log(record);
      this.visible = true;
      this.title = "编辑联屏";
      this.btnShow = false;
      this.dataInfo = record.list
      this.scrollId = record.scrollId
      this.bclassId = bclassId
      this.getUIList()
    },

    handleSubmit() {
      // 新增确认操作
      this.confirmLoading = true;
      this.eidt();
    },
    eidt() {
      if(this.dataInfo.length< 2){
        this.$message.error('至少选择两个大屏')
        this.confirmLoading = false;
        return
      }
      const bigScreenIdList = []
      const secsList = []
      for (let i = 0; i < this.dataInfo.length; i++) {
        const e = this.dataInfo[i];
        if(!e.time){
          this.$message.error('第'+ (i+1) +'行未填写时间')
        this.confirmLoading = false;
        return
        }
        bigScreenIdList.push(e.bigScreenId)
        secsList.push(e.time)
      }
      console.log(bigScreenIdList, secsList);
      scrollShowSet(
        {
          bigScreenIds: bigScreenIdList.join(','),
          scrollId: this.scrollId,
          secs: secsList.join(',')
        },
        (resp) => {
          console.log(resp);
          if (resp.code === 0) {
            this.confirmLoading = false;
            this.$message.success(`修改成功`);
            this.visible = false;
            this.$emit("ok", '');
            // this.$router.go(0)
          } else {
            this.$message.error(resp.errMsg);
            this.confirmLoading = false;
          }
        },
        (err) => {
          this.confirmLoading = false;
          console.log("增加错误", err);
        }
      );
    },
    handleCancel() {
      // 取消操作
      this.visible = false;
      this.platform = 0;
      this.$emit("ok", '');
      /*
      1、
      */
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.AddJointScreen_bottom {
  width: 100%;
  margin-top: 20px;
}

.AddJointScreen_bottom_box {
  margin-bottom: 20px;
}

.AddJointScreen_bottom_box_title {
  font-size: 18px;
  color: #333;
  display: inline-block;
  margin-bottom: 10px;
}

.AddJointScreen_bottom_box_bottom {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.AddJointScreen_bottom_box_bottom_imgBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 49%;
  margin-right: 2%;
  margin-bottom: 2%;
  cursor: pointer;
}

.AddJointScreen_bottom_box_bottom_imgBox:nth-child(2n) {
  margin-right: 0;
}

.AddJointScreen_bottom_box_bottom_imgBox_img {
  width: 100%;
  height: 218px;
}

.AddJointScreen_bottom_box_bottom_imgBox_span {
  display: inline-block;
  margin-top: 10px;
}
</style>
